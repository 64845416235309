.hud_container {
  position: absolute;
  top: 0px;
  left: 45vw;
  align-items: center;
  /* text-align: center; */
  /* z-index: 0; */
  font-size: 2rem;
  color: rgb(235, 244, 252);
  font-family: "Skate", monospace;
  text-shadow: 0 0 2px rgb(0, 0, 0), 0 0 8px #4dfff0, 0 0 16px #4deaff;
  opacity: 0;
  transition: opacity 1s ease;
}

.hud_velocity {
  min-width: 200px;
  position: absolute;
  margin-left: 34vw;
  padding-top: 10px;
  font-size: 2rem;
  color: #ffffff;
  text-shadow: 0 0 2px rgb(0, 0, 0), 0 0 8px #4dfff0, 0 0 16px #4deaff;
  /* text-shadow: 0 0 2px rgb(0, 0, 0), 0 0 20px #ff4d4d; */
}

.show {
  opacity: 1;
}

.under_construction {
  position: absolute;
  margin-left: 2vw;
  margin-top: 30vw;
  font-size: 1.5rem;
  color: #ffffff;
  text-align: center;
  font-family: "Arial";
  /* opacity: 5; */
  /* text-shadow: 0 0 2px rgb(0, 0, 0), 0 0 20px #4d68ff; */
}

.current_points {
  position: absolute;
  margin-left: 4.2vw;
  margin-top: 21vw;
  font-size: 2rem;
  /* color: #ffffff; */
  /* opacity: 5; */
  text-shadow: 0 0 2px rgb(0, 0, 0), 0 0 20px #4dff4d;
}

.current_goals {
  position: absolute;
  margin-left: -5vw;
  margin-top: 3vw;
  font-size: 2rem;
  color: #ffffff;
  text-shadow: 0 0 2px rgb(0, 0, 0), 0 0 20px #4dff4d;
}

.current_goals_x {
  position: absolute;
  margin-left: 15vw;
  margin-top: 3vw;
  font-size: 2rem;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 0 2px rgb(0, 0, 0), 0 0 20px red;
  /* opacity: 20;
  z-index: -10; */
}

.fullscr_button {
  position: absolute;
  margin-left: 4.2vw;
  margin-top: 26vw;
  font-size: 2rem;
  background-color: transparent ;
  border: none;
  color: none;
  cursor: pointer;
}

@media (min-width: 781px) and (max-width: 960px) {
  .current_points {
    position: absolute;
    margin-left: 4.2vw;
    margin-top: 80vw;
    font-size: 3rem;
    /* color: #ffffff; */
    /* opacity: 5; */
    text-shadow: 0 0 2px rgb(0, 0, 0), 0 0 20px #4dff4d;
  }

  .current_goals {
    position: absolute;
    margin-left: -30vw;
    margin-top: 3vw;
    font-size: 3rem;
    color: #ffffff;
    text-shadow: 0 0 2px rgb(0, 0, 0), 0 0 20px #4dff4d;
  }

  .current_goals_x {
    position: absolute;
    margin-left: 35vw;
    margin-top: 3vw;
    font-size: 3rem;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 0 2px rgb(0, 0, 0), 0 0 20px red;
    /* opacity: 20;
    z-index: -10; */
  }

  .under_construction {
    position: absolute;
    margin-left: -3vw;
    margin-top: 450px;
    font-size: 1.5rem;
    text-align: center;
  }

  .fullscr_button {
    position: absolute;
    margin-left:48vw;
    margin-top: 2vw;
    font-size: 2rem;
    background-color: transparent ;
    border: none;
    color: none;
    cursor: pointer;
  }
}

@media (max-width: 780px) {
  .current_points {
    position: absolute;
    /* margin-left: 2vw; */
    margin-top: 300px;
    font-size: 1.5rem;
    text-align: center;
  }

  .current_goals {
    position: absolute;
    margin-left: -32vw;
    margin-top: 50px;
    font-size: 1.5rem;
    /* text-align: center; */
  }

  .current_goals_x {
    position: absolute;
    margin-left: 35vw;
    margin-top: 50px;
    font-size: 1.5rem;
    /* text-align: center; */
  }

  .under_construction {
    position: absolute;
    margin-left: -10vw;
    margin-top: 350px;
    font-size: 1.5rem;
    text-align: center;
  }

  .fullscr_button {
    position: absolute;
    margin-left: 42vw;
    margin-top: 2vw;
    font-size: 2rem;
    background-color: transparent ;
    border: none;
    color: none;
    cursor: pointer;
  }

}