@font-face {
  font-family: "Sugar Punch";
  src: local("Sugar Punch"),
    url(../styles/fonts/sugarpunch.otf) format("opentype");
}

@font-face {
  font-family: "Skate";
  src: local("Skate"), url(../styles/fonts/skate.ttf) format("opentype");
}

.main_menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 1s ease;
  transition: background-color 1s ease;
  z-index: 1000;
  backdrop-filter: blur(4px);
  transition: opacity 1s;
  opacity: 0;
  cursor: grab;
}

.info{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  /* transition: opacity 1s ease;
  transition: background-color 1s ease; */
  /* z-index: 1000; */
  backdrop-filter: blur(4px);
  background: white;
  transition: opacity 1s;
  opacity: 0;
  cursor: grab;
}

.show {
  opacity: 1;
}

.logo {
  max-width: 80%;
  max-height: 80%;
  width: 12vw;
  /* margin-top: 1rem; */
  /* margin-bottom: 1rem; */
}

.name_game {
  margin-top: 3rem;
  font-family: "Sugar Punch", monospace;
  font-size: 5rem;
  color: aliceblue;
}

.name_game_info {
  margin-top: 2rem;
  font-family: "Sugar Punch", monospace;
  font-size: 3rem;
  color: black;
  align-items: center;
}

.name_pinball {
  margin-top: 0.5rem;
  font-family: "Sugar Punch", monospace;
  font-size: 2rem;
  color: aliceblue;
}

.column_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.play_button {
  background-color: transparent;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 2rem;
  font-family: "Skate", monospace;
  cursor: pointer;
}

.play_button:hover {
  text-shadow: 0 0 2px rgb(0, 0, 0), 0 0 8px #4dff4d, 0 0 16px #53ff4d;
  min-width: 50%;
  border-radius: 10px;
  transition: background-color 1s ease;
}

.play_button_info {
  background-color: transparent;
  border: none;
  color: black;
  font-size: 1em;
  font-family: "Skate", monospace;
  cursor: pointer;
}

.play_button_info:hover {
  text-shadow: 0 0 2px rgb(0, 0, 0), 0 0 8px #4dff4d, 0 0 16px #53ff4d;
  min-width: 50%;
  border-radius: 10px;
  transition: background-color 1s ease;
}

.lang_button {
  background-color: transparent;
  /* border: none; */
  color: rgb(255, 255, 255);
  font-size: 1rem;
  /* font-family: "Skate", monospace; */
  cursor: pointer;
}

.info_button {
  /* margin-top: -80px; */
  background-color: transparent;
  /* border: none; */
  color: rgb(255, 255, 255);
  font-size: 1rem;
  /* font-family: "Skate", monospace; */
  cursor: pointer;
}


.fanny_button {
  margin-top: -50px;
  background-color: transparent;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 0.8rem;
  font-family: "Skate", monospace;
  cursor: pointer;
}

.fanny_button:hover {
  text-shadow: 0 0 1px rgb(0, 0, 0), 0 0 4px #4dff4d, 0 0 8px #53ff4d;
  min-width: 10%;
  border-radius: 5px;
  transition: background-color 1s ease;
}

.menu_button {
  background-color: transparent;
  /* border: none; */
  /* color: rgb(255, 255, 255); */
  font-size: 1rem;
  font-family: "Arial", monospace;
  cursor: pointer;
}

/* .info_button:hover {
  text-shadow: 0 0 2px rgb(0, 0, 0), 0 0 8px blue, 0 0 16px blue;
  min-width: 50%;
  border-radius: 10px;
  transition: background-color 1s ease;
} */

.random_fact {
  color: aliceblue;
  padding-top: 10px;
  font-size: 1rem;
}

.options_label {
  padding-top: 1rem;
  color: aliceblue;
  font-size: 1rem;
}

.links {
  position: absolute;
  margin-left: 2vw;
  /* margin-top: 50px;
  font-size: 1.5rem; */
  /* text-align: center; */
}

.shake {
  animation: shake 20s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
}

.block_all {
  pointer-events: none;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-10rem, 3rem, 0);
  }

  20%,
  80% {
    transform: translate3d(10rem, -5rem, -0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(41rem, 6rem, 0);
  }

  40%,
  60% {
    transform: translate3d(16rem, -6rem, 0);
  }
}

/* @media (min-width: 769px) and (max-width: 900px) {
  .name_game {
    font-size: 3rem;
  }
} */

@media (max-width: 768px) {
  .name_game {
    margin-top: 1.5rem;
    font-size: 3rem;
  }

  .logo {
    max-width: 80%;
    max-height: 80%;
    width: 50vw;
    margin-top: 0;
    margin-bottom: 0;
  }

  .info_text {
    margin-left: 2vw;
    font-size: 0.8rem;
  }

  .links {
    font-size: 0.8rem;
  }

}