* {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    width: 100%;
    height: 99%;
    margin: 2;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
  }

  #root {
    overflow: auto;
  }

  body {
    position: fixed;
    overflow: hidden;
    overscroll-behavior-y: none;
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
    color: black;
    background: #272730;
    /* background: url(../../public/sky.jpg) no-repeat center center; */
    cursor: url("https://github.com/chenglou/react-motion/raw/master/demos/demo8-draggable-list/cursor.png") 39 39, auto;
    image-rendering: pixelated;
  }

  .startup {
    position: absolute;
    top: 50px;
    left: 50px;
    color: rgb(204, 158, 158);
    font-size: 1.2em;
  }

  /* @media (max-width: 900px) {
    html,
    body,
    #root {
      width: 100%;
      height: 99%;
      margin: 2;
      padding: 0;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      overflow: hidden;
    }
  } */
